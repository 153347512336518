import React, { useRef, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Constants, userToken } from "../../Constants";
import userStore from "../../Store";

const TeamChats = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const chatContainerRef = useRef(null);
  const { user } = userStore();

  const [load, setLoad] = React.useState(true);
  const [players, setPlayers] = React.useState([]);
  const [teams, setTeams] = React.useState([]);
  const [chats, setChats] = React.useState([]);
  const [messages, setMessages] = React.useState("");
  const [settings, chatSettings] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  useEffect(() => {
    // Scroll to the bottom of the chat container
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chats]);
  React.useEffect(() => {
    (async () => {
      let url = Constants.teamChats + id;
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + userToken(),
        },
      });
      let data = await response.json();
      if (data.status === 1) {
        setPlayers(data.response.players);
        setTeams(data.response.team);
        setIsLoading(false);
      }
    })();

    const interval = setInterval(async () => {
      let url = Constants.getTeamChats + id;
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + userToken(),
        },
      });
      let data = await response.json();
      // console.log(data);
      if (data.status === 1) {
        setChats(data.response.chats);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [id, load]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData(e.target);

    let url = Constants.sendTeamChats + id;
    let response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + userToken(),
      },
      body: formData,
    });
    let data = await response.json();
    e.target.reset();
  };
  const minHeightStyle = {
    minHeight: "100vh",
  };
  //    const eventDataObj = JSON.parse(chats[26].event_data)
  //    console.log(eventDataObj.title)

  return (
    <>
      <div className="col-lg-7" style={minHeightStyle}>
        <div className="middle-top-head my-3">
          <div className="d-flex align-items-center justify-content-between">
            <div className="tophead-title">
              <h2 className="mb-0">
                <span onClick={() => navigate(-1)} className="text-black">
                  <i className="fas fa-arrow-left"></i> Back
                </span>
              </h2>
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className="text-center">
            <div
              className="spinner-border text-secondary"
              style={{
                width: "4rem",
                height: "4rem",
                position: "fixed",
                top: "50%",
                left: "42%",
              }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="shadow-sm border bg-white rounded overflow-hidden mb-4">
            <div className="p-3">
              <div className="chat-main-body">
                <div className="chat-main-heading">
                  <div className="main-heading-profile align-items-center">
                    {teams?.team_logo ? (
                      <img
                        height="80"
                        width="100"
                        src={`/uploads/teams/${teams.team_logo}`}
                        alt="no-p_image"
                        className="img-fluid"
                      />
                    ) : (
                      <img
                        height="80"
                        width="100"
                        src="https://img.freepik.com/premium-vector/default-image-icon-vector-missing-picture-page-website-design-mobile-app-no-photo-available_87543-11093.jpg?w=826"
                        alt="no-p_image"
                        className="img-fluid"
                      />
                    )}

                    <div className="main-heading-title">
                      <p>{teams?.team_name}</p>
                      {players.map((plr, index) => {
                        return (
                          <span
                            className="badge bg-maroon m-1"
                            key={index}
                          >{`${plr?.first_name} ${plr?.last_name}`}</span>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="main-body" ref={chatContainerRef}>
                  {chats?.map((chat, index) => {
                    return (
                      <div
                        className={
                          chat?.userId === user?.id
                            ? `main-body-content-right`
                            : `main-body-content-left`
                        }
                        key={index}
                      >
                        <div
                          className={
                            chat?.userId === user?.id
                              ? `main-body-right`
                              : `main-body-left`
                          }
                        >
                          <span>
                            {chat?.userId === user?.id ? (
                              ""
                            ) : (
                              <img
                                className="img-fluid"
                                src={
                                  chat?.photo
                                    ? `/uploads/profile_pictures/${chat.photo}`
                                    : "https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
                                }
                                alt={chat?.photo || "default_image"}
                              />
                            )}
                            <p>
                              {JSON.parse(chat?.event_data) === null
                                ? chat?.message
                                : JSON.parse(chat?.event_data)?.title}
                            </p>
                          </span>
                          <small>
                            {chat?.userId === user?.id
                              ? "Me"
                              : `${chat?.first_name} ${chat?.last_name}`}
                            | {new Date(chat?.sentDate).toLocaleString()}
                          </small>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="chat-bottom">
                  <form onSubmit={handleSubmit}>
                    <div className="d-flex align-items-center">
                      <div className="flex-fill pe-2">
                        <textarea
                          id="chat"
                          placeholder="Enter Message"
                          className="chat-message"
                          name="msg"
                        ></textarea>
                      </div>
                      <div className="chatbtnlist">
                        <button className="btn">
                          <i className="fas fa-smile"></i>
                        </button>
                        <button className="btn" type="submit">
                          <i className="fas fa-paper-plane"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default TeamChats;
