import React from "react";
import { Link } from "react-router-dom";
import { Constants, userToken } from "../../Constants";
import Modal from "react-bootstrap/Modal";
import userStore from "../../Store.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import NoImg from "../../static/noimageteam.png";
import { useState } from "react";
import { FaArrowAltCircleDown } from "react-icons/fa";

const MySwal = withReactContent(Swal);

const textClamp = (num, max) => {
  return num <= max ? num : max + "+";
};
const MainArea = () => {
  const { user } = userStore();
  // console.log(user);
  const [show, setShow] = React.useState(false);
  const [teamRequest, setTeamRequest] = React.useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [teams, setTeams] = React.useState([]);
  const [message, setMessage] = React.useState({});
  const [load, setLoad] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchTeams = async () => {
      try {
        const url = Constants.listTeam;
        const response = await fetch(url, {
          headers: {
            Authorization: "Bearer " + userToken(),
          },
        });
        const data = await response.json();
        const sortedTeams = data.response.sort((a, b) => b.id - a.id);

        setTeams(sortedTeams);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching teams:", error);
        setIsLoading(false);
      }
    };
    fetchTeams();
  }, [load]);
  // console.log(teams)

  React.useEffect(() => {
    const fetchPendingRequests = async () => {
      try {
        const url = Constants.pendingTeamPlayer;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + userToken(),
          },
        });
        const data = await response.json();
        if (data.status === 1) {
          setTeamRequest(data.response);
        }
      } catch (error) {
        console.error("Error fetching pending team requests:", error);
      }
    };
    fetchPendingRequests();
  }, [load]);

  const handleDelete = async (id) => {
    try {
      const confirmResult = await Swal.fire({
        icon: "warning",
        title: "Are you sure?",
        text: "Do you really want to delete this team?",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete",
        cancelButtonText: "Cancel",
      });

      if (confirmResult.isConfirmed) {
        const url = Constants.deleteTeams + id;
        const response = await fetch(url, {
          headers: {
            Authorization: "Bearer " + userToken(),
          },
        });

        const data = await response.json();

        if (response.ok && data.status === 1) {
          window.sessionStorage.setItem("type", "danger");
          window.sessionStorage.setItem("message", data.message);

          Swal.fire({
            icon: "success",
            title: "Team deleted!",
            showConfirmButton: false,
            timer: 1500,
          });

          setLoad((prevLoad) => !prevLoad);
        } else {
          throw new Error("Failed to delete team.");
        }
      }
    } catch (error) {
      console.error("Error deleting team:", error);

      window.sessionStorage.setItem("type", "danger");
      window.sessionStorage.setItem("message", "Failed to delete team.");
    }
  };

  React.useEffect(() => {
    let url = Constants.pendingTeamPlayer;
    (async () => {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + userToken(),
        },
      });
      let data = await response.json();
      if (data.status === 1) {
        setTeamRequest(data.response);
      }
    })();
  }, [load]);

  const handleAccept = async (id, player_id) => {
    try {
      const url = Constants.acceptTeamPlayer + player_id;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + userToken(),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          teamId: id,
        }),
      });
      const data = await response.json();
      if (data.status === 1) {
        setShow(false);
        setLoad((prevLoad) => !prevLoad);
      }
    } catch (error) {
      console.error("Error accepting team request:", error);
    }
  };

  const handleReject = async (id) => {
    try {
      const url = Constants.rejectTeamPlayer + id;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + userToken(),
        },
      });
      const data = await response.json();
      if (data.status === 1) {
        setShow(false);
      }
    } catch (error) {
      console.error("Error rejecting team request:", error);
    }
  };
  //const [showOptions, setShowOptions] = useState(false);

  // const toggleOptions = () => {
  //   setShowOptions(!showOptions);
  // };

  // const closeOptions = () => {
  //   setShowOptions(false);
  // };
  const TeamCard = ({ team, handleDelete }) => {
    const [showOptions, setShowOptions] = useState(false);

    const toggleOptions = () => {
      setShowOptions(!showOptions);
    };

    const closeOptions = () => {
      setShowOptions(false);
    };

    return (
      <div className="col-lg-4 col-md-6 " style={{ minHeight: "35vh" }}>
        <div className="card rounded shadow-sm teamcard ">
          <div className="card-image">
            {/* {user?.id === team?.team_creator && (
               <div className="options-button" onClick={toggleOptions}>
               {showOptions ? (
                   <i className="fa fa-times" aria-hidden="true"></i>
               ) : (
                   <i className="fa fa-bars" aria-hidden="true"></i>
               )}
           </div>
            )} */}
            {team?.team_logo ? (
              <img
                src={`https://sportthon.com/get-res?url=https://api.sportthon.com/uploads/teams/${team?.team_logo}`}
                alt={team?.team_logo}
              />
            ) : (
              <img src={"../../static/icona.jpg"} />
            )}
          </div>
          <div className="card-body border-top">
            <div className="d-flex justify-content-between align-items-center mb-1">
              <h4 className="tname fs-6 mb-0">
                <Link
                  to={`/team-member/${team?.team_id}`}
                  className="text-maroon"
                >
                  {team.team_name}
                </Link>
              </h4>
              <div className="ticon">
                <img
                  src="/assets/frontend/images/icons/icon-play.png"
                  alt="icon-play.png"
                />
              </div>
            </div>
            <p className="fs-13 text-secondary">
              <i className="fas fa-map-marker-alt"></i> {team?.team_address}
            </p>
            {user?.id === team?.team_creator ? (
              <span className="text-success text-uppercase font-weight-bold">
                Created By Me
              </span>
            ) : (
              <span className="text-primary text-uppercase font-weight-bold">
                Joined By Me
              </span>
            )}
          </div>
          {/* {showOptions && user?.id === team?.team_creator && (
            <div className=" card-options " onClick={closeOptions}>
              <div className="row">
                <div className="col-6">
                  <Link
                    className="btn btn-warning m-2"
                    to={`/edit-team/${team?.team_id}`}
                  >
                    Edit
                  </Link>
                </div>
                <div className="col-6 text-end">
                  <span
                    className="btn btn-danger m-2"
                    onClick={() => handleDelete(team?.team_id)}
                  >
                    Delete
                  </span>
                </div>
              </div>
            </div>
          )} */}
        </div>
      </div>
    );
  };
  const minHeightStyle = {
    minHeight: "85vh",
  };

  return (
    <>
      <div className="col-lg-7" style={minHeightStyle}>
        <div className="middle-top-head my-3">
          <div className="d-flex align-items-center justify-content-between">
            <div className="tophead-title"></div>
            <div className="d-flex align-items-center">
              <div className="me-4">
                <span onClick={handleShow} className="color-black fs-5">
                  <i
                    className="fas fa-users cursor-pointer"
                    data-badge={textClamp(teamRequest?.length ?? 0, 9)}
                  ></i>
                </span>
              </div>
              <div>
                <Link to="/create-team" className="btn btn-marooon">
                  Create Team<i className="fas fa-plus ms-2"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="shadow-sm border bg-white rounded overflow-hidden mb-4">
          <div className="tab-content p-3">
            <div
              className={`alert alert-${message?.type}`}
              onClick={() => setMessage({})}
            >
              {message.message}
            </div>
            <div className="row">
              {isLoading ? (
                <div className="text-center">
                  <div
                    className="spinner-border text-secondary"
                    style={{
                      width: "4rem",
                      height: "4rem",
                      position: "fixed",
                      top: "50%",
                      left: "42%",
                    }}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                teams?.map((team, index) => (
                  <TeamCard
                    key={index}
                    team={team}
                    handleDelete={handleDelete}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Request List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {teamRequest?.map((member, index) => {
            return (
              <div key={index}>
                <p>{member?.team_name}</p>
                <span
                  className="btn btn-success fa fa-check"
                  onClick={() => handleAccept(member?.team_id, member?.id)}
                ></span>
                <span
                  className="btn btn-danger fa fa-times-circle"
                  onClick={() => handleReject(member?.id)}
                ></span>
              </div>
            );
          })}
        </Modal.Body>
      </Modal>
    </>
  );
};
export default MainArea;
